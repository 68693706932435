.ant-tag {
    padding: 5px 10px !important;
    border-radius: 20px !important;

    .prepend-text {
        background: #FFBB21;
        padding: 2px 4px;
        color: #22222B;
        font-size: 0.7rem;
        border-radius: 2px;
        margin-right: 4px;
    }
}
