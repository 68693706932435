// menu
// Ant menu overrides
// main variables are location in theme-variables.less file

.ant-layout-sider-trigger{
  position: absolute;
  top: 21px;
  height: 30px;
  line-height: 25px;
  width: 30px !important;
  right: -16px;
  background-color:@color-blue2;
  border-radius: 50%;
  color:@color-blue1;
  border:2px solid @primary-color;
  z-index: 10;
  .anticon{
    font-size: 12px;
    color: @primary-color;
  }
}
.ant-layout-sider-zero-width-trigger{
  position: absolute;
  top: 21px;
  height: 30px;
  line-height: 25px;
  width: 30px !important;
  right: -16px;
  background-color:@color-white;
  border-radius: 50%;
  color:@color-white;
  border:2px solid @primary-color;
  z-index: 10;
  display: none;
  .anticon{
    font-size: 14px;
    color: #000000;
  }
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
  width: 17px;
  height: 17px;
  margin-right: 10px;
  min-width: inherit;
  font-size:inherit;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: inherit;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none;
}
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
  width: 100%;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  right: inherit;
  left: 0;
  border-right: 3px solid @color-off-white3;
}
.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline{
  margin: 40px 0;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background: @secondary-color;
  border-radius: 10px;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span, .ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item.ant-menu-item-selected{
  // border-left: 5px solid @color-off-white3;
}
.ant-menu.ant-menu-dark .ant-menu-item,
.ant-menu.ant-menu-dark .ant-menu-submenu-title{
  // border-left: 5px solid transparent;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a{
  color: @color-gray9;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover{
  color: @secondary-color;
}
.ant-menu-inline .ant-menu-item{
  font-weight: 500;
}
.ant-layout-sider-zero-width-trigger{
  display: none;
}

.mobile-menu{
  display: none;
}

@media (max-width: 767px) {

  .main-sider.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below.ant-layout-sider-zero-width{
    .ant-layout-sider-zero-width-trigger{
      right: -44px;
    }

}
  .main-sider.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below{
    .ant-layout-sider-zero-width-trigger{
      right: 20px;
    }
  }

  .ant-layout-sider-zero-width-trigger{
    position: absolute;
    top: 21px;
    height: 30px;
    line-height: 25px;
    width: 30px !important;
    background-color:@color-white;
    border-radius: 50%;
    color:@color-white;
    border:2px solid @primary-color;
    z-index: 10;
    display: block;
    .anticon{
      font-size: 14px;
      color: #000000;
    }
  }
  .ant-layout-sider-zero-width.main-sider{
    padding:0;
  }
  .ant-layout-sider{
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
  }

  .desktop-menu{
    display: none;
  }
  .mobile-menu{
    display: block;
  }
}

@media (max-width: 992px) {
  .ant-layout-sider-zero-width-trigger{
    display: block;
  }
  .ant-layout.ant-layout-has-sider{
    overflow-x: hidden;
  }
  .ant-layout-sider-zero-width.main-sider {
    padding: 0;
  }
  .ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0!important;
    max-width: 0!important;
    min-width: 0!important;
    width: 0!important;
  }
  .ant-layout-header {
    padding: 0 15px;
  }
  .ant-layout-sider-zero-width-trigger{
    right: -44px;
  }
  .desktop-menu{
    display: none;
  }
  .mobile-menu{
    display: block;
  }
}
