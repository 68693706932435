//  Header Section

.ant-layout-header{
   margin-top: 40px;
  .header-left{
    .welcome-msg{
      font-weight: 500;
      font-size: 20px;
      color: #808191;
      line-height: 25px;
      margin-bottom: 10px;
    }
    .team-name{
      font-weight: 700;
      font-size: 46px;
      color: #11142D;
      line-height: 40px;
    }
  }
  .header-right{
    line-height: 54px;
    text-align: right;

    .ant-badge-status{
      line-height: 1;
    }

    .hd-inbox,
    .hd-notifications{
      margin-right: 30px;
      cursor: pointer;
    }
    .hd-notifications{

      // padding-right: 30px;
      &:after{
        content: "";
        display: inline-block;
        border-right: 1px solid @color-gray4;
        height: 20px;
        margin-left: 20px;
        position: absolute;
      }
    }

    .fa-bell,
    .fa-comments{
      font-size: 18px;
      color:@color-gray9;
    }
    .ant-badge-dot{
      width: 8px;
      height: 8px;
    }
    .hd-name{
      margin-left: 10px;
      font-family: @font-family;
      font-size: 16px;
      color: @color-gray9;
      font-weight: 600;

      .anticon-down{
        font-size: 16px;
        color: @color-gray9;
        font-weight: 600;
        margin: 0 5px;
      }
    }
    .avatar{
     // border: 2px solid @primary-color;
     // box-shadow: 0px 0px 3px @primary-color;
      width: 46px;
      height: 46px;
    }


    .ant-badge{
      margin-right: 30px;
      cursor: pointer;
      padding: 8px 45px 8px 10px;
      .anticon.anticon-bell{
        color: @color-gray9;
        font-size: 20px;
        font-weight: 700;
      }
      .ant-badge-count, .ant-badge-dot,
      .ant-badge .ant-scroll-number-custom-component{
        position: absolute;
        top: 17px;
        right: 21px;
        background: transparent;
        color: @text-color;
        font: @font-family;
        font-weight: 700;
        font-size: 16px;
        border: none;
        box-shadow: none;
      }
      &.ant-popover-open{
        background: rgba(128, 129, 145, 0.1);
        border-radius: 27px;
        padding: 8px 45px 8px 10px;
      }
    }



  }
}
.ant-btn.lang-btn.ant-btn-link{
  font-size: 14px !important;
}

.language-pop{
  &.ant-popover .ant-menu .ant-menu-item{
    padding: 10px 0;
    a{
      font-size: 16px;
      font-family: @font-family;
      color: @text-color;
      font-weight: 700;
    }

  }
  .ant-radio-group{
    .ant-typography-secondary{
      font-size: 12px;
      font-family: @font-family;
      color: @text-color-secondary;
      font-weight: 700;
     display: block;
    }
    .ant-radio-wrapper{
      font-size: 16px;
      font-family: @font-family;
      color: @text-color;
      font-weight: 700;
    }
  }
  .ant-menu-item-divider{
    margin:10px 0 !important;
  }
}

//.team-select{
//  max-width: 412px !important;
//  width: 100% !important;
//}
@media (max-width: 767px) {
  .ant-layout-header{
    margin-top: 40px;
    .header-left{
      margin-top: 80px;
      position: absolute;
      .welcome-msg{
        font-weight: 500;
        font-size: 18px;
        color: @text-color;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .team-name{
        font-weight: 700;
        font-size: 22px;
        color: @text-color;
        line-height: 22px;
      }
    }
    .header-right{
      line-height: 22px;
      text-align: right;
      margin-top: 20px;
      .ant-badge-status{
        line-height: 1;
      }

      .hd-inbox,
      .hd-notifications{
        margin-right: 30px;
        cursor: pointer;
      }
      .hd-notifications{

        // padding-right: 30px;
        &:after{
          content: "";
          display: inline-block;
          border-right: 1px solid @color-gray4;
          height: 20px;
          margin-left: 20px;
          position: absolute;
        }
      }

      .fa-bell,
      .fa-comments{
        font-size: 18px;
        color:@color-gray9;
      }
      .ant-badge-dot{
        width: 8px;
        height: 8px;
      }
      .hd-name{
        margin-left: 10px;
        font-family: @font-family;
        font-size: 14px;
        color: @color-gray9;
        font-weight: 600;
        display: inline-block;

        .anticon-down{
          font-size: 14px;
          color: @color-gray9;
          font-weight: 600;
          margin: 0 10px;
        }
      }
      .avatar{

        width: 30px;
        height: 30px;
      }


      .ant-badge{
        margin-right: 10px;
        cursor: pointer;
        padding: 8px 45px 8px 10px;
        .anticon.anticon-bell{
          color: @color-gray9;
          font-size: 20px;
          font-weight: 700;
        }
        .ant-badge-count, .ant-badge-dot,
        .ant-badge .ant-scroll-number-custom-component{
          position: absolute;
          top: 17px;
          right: 21px;
          background: transparent;
          color: @text-color;
          font: @font-family;
          font-weight: 700;
          font-size: 16px;
          border: none;
          box-shadow: none;
        }
        &.ant-popover-open{
          background: rgba(128, 129, 145, 0.1);
          border-radius: 27px;
          padding: 8px 45px 8px 10px;
        }
      }



    }
  }
}
//
//@media (max-width: 425px){
//  .team-select{
//    max-width: 301px !important;
//    width: 100% !important;
//  }
//}
//@media (max-width: 375px){
//  .team-select{
//    max-width: 251px !important;
//    width: 100% !important;
//  }
//}
@media (max-width: 320px){
  .team-select{
    max-width: 196px !important;
    width: 100% !important;
  }
}

