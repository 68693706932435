
@import "theme-variables.less";
@import "side-menu.less";
@import "header.less";
html,body{
  margin: 0;
}

//  heading


//  input

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 40px @color-gray11 inset !important;
}
.ant-input{
  border-top:none;
  border-right: none;
  border-left: none;
  -webkit-appearance: none;
  outline: none;
  border-radius: 10px;
}
.ant-input-lg,
.ant-select-lg{
  font-size: 15px;
}
.ant-form-item-label > label{
  font-size: 12px;
  font-weight: 600;
  color: @text-color;
}
.ant-select-arrow{
  margin-top: -9px !important;
}
.ant-select-arrow .ant-select-arrow-icon svg{
  font-size: 11px;
  color: @text-color-secondary;
}
.ant-select-selection__placeholder{
  color: @text-color-secondary;
}

.ant-checkbox-inner{
  border-radius: 3px;
  border-color: @primary-color;
}
.ant-input:focus{
  outline: none;
  box-shadow: none;
  border: 1px solid @primary-color;
  box-shadow: none !important;
}

.ant-select-dropdown-menu-item{
  color: @text-color-secondary !important;
}

// modal
.ant-modal-content{
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.ant-modal-header{
  border-radius: 17px 17px 0 0;
  border-bottom: 0px;
  border-radius: 10px;
  padding-top: 40px;
}

.ant-modal-footer {
  border-top: 0px;
  padding-bottom: 40px;
}

.ant-modal-close-x{
  height: 36px;
  width: 36px;
  background-color:@color-gray11;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;

  .ant-modal-close-icon{
    &.anticon {
      color: @text-color;
      font-size: 15px;
      svg{
        color: @text-color;
      }
    }
  }
}

.ant-modal-title {
  font-weight: 800;
  font-size: 24px;
}

// ant table

.ant-table-header-column{
  .ant-table-column-title{
    font-size: 14px;
    line-height: 16px;
    color: @color-gray10;
    letter-spacing: 0;
    font-family: @font-family;
    font-weight: 400;

  }
}

.ant-table-thead > tr > th{
}

.ant-table-tbody > tr > td{
  font-size: 14px;
  line-height: 20px;
  font-family: @font-family;
  letter-spacing: 0;
  color: @text-color;
  font-weight: 600;
}

// notifications

.ant-notification{
  margin-right: 15px !important;
}

.ant-alert-message{
  color: @color-green1 !important;
}

// icons

.avatar-large{
  border-radius: 10px;
  //border: 3px solid #E9E9F0;
  width: 72px;
  height: 72px;
}
.anticon{
  font-size: 18px;
}

.icon-large{
  font-size: 25px;
}
.icon-large2{
  .anticon{
    width: 65px;
  }
}
.anticon-close{
  color: @color-gray8 !important;
  font-size: 15px;
}

.ant-layout-sider{
  background-color:@color-white;
  border-right: 1px solid @border-color-base;
//  height: 100vh;
}
.ant-layout-has-sider{
 // position: relative;
  //height: 100%;
}
.main-sider{
  width: 240px;
  padding:40px 20px;
//  height: 100%;
 // position: absolute;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content{
  overflow-x: inherit !important;
}




.ant-layout-sider-collapsed{
  h3.logo-name{
    text-align: center;
    font-size: 30px;
    padding: 9px 20px;
  }
}

h3.logo-name{
  margin-bottom: 0;
  background-color:@color-white;
  /*font-family: source_sans_probold;
  font-size: 15px;
  color: @color-white;
  text-transform: uppercase;*/
  text-align: left;
  letter-spacing: 4px;
  padding: 7px 17px;
}



//  card

.ant-card{
  background: #FFFFFF;
  box-shadow: 0px 0px 150px rgba(217, 217, 224, 0.41);
  border-radius: 20px;
}


.account-menu{
  .ant-menu-item:active{
    background-color: transparent;
  }
}


.ant-popover{
  .ant-menu{
    background: inherit;
    .ant-menu-item{
      height: 40px;
      line-height: 40px;
      text-align: left;
      & > a {
        color: @color-gray5;
        font-size: 13px;
        &:hover{
          color: @primary-color;
        }
      }
      color: @color-gray5;
      font-size: 13px;
      &.ant-menu-item-selected{
        background-color: inherit;
      }
    }
  }
  .ant-popover-title{
    font-size: 17px;
    padding: 15px 0;
  }
}

.cancel-button{
  float: left;
  border: none;
  margin-right: 11px;
  width: 159px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgb(139 139 139 / 20%);
  border-radius: 13px;
}

.confirm-action-button{
  margin-right: 11px;
  width: 159px;
  height: 50px;
  border-radius: 10px;
  background: #0c87ec;
  box-shadow: 0px 10px 30px rgba(139, 139, 139, 0.2);
  border-radius: 13px;
}

.inbox-popover{
  width: 460px;
  text-align: center;

}

.notification-popover{
  width: 400px;
  text-align: center;
}

//  Main Content Section

.main-content{
  padding: 40px 20px;
  min-height: 800px;
  height: 100vh;
  margin:0 auto;
  width: 100%;
}
.main-header{
  width: 100%;
  margin:0 auto;
  padding: 30px 20px;
}

// Modal Content Section

.modal-content{
  text-align: center;
}

// message container

.message-content{
  margin: 30px 75px 30px 75px;
}

// Drawer

.ant-drawer.ant-drawer-open .ant-drawer-mask{
 opacity: 0.3 !important;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.3, 0.3, 0.1, 0.3);
}

//  Buttons

.ant-btn-background-ghost.ant-btn-link{
  color:@primary-color !important;
  background-color: transparent;
}

.btn-show-more {
  button{
    font-size: 13px !important;
    padding: 0;
  }
}
.tb-btn-show-more{
  text-align: right;
  margin-top: 10px;
}

.text-right{
  text-align: right !important;
}

.ant-btn{
  font-size: 14px !important;
  font-weight: 500;
}
.ant-btn.ant-btn-background-ghost {
  color: @primary-color;
}
.ant-btn.ant-btn-primary[disabled]:hover {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn.ant-btn-background-ghost.ant-btn-primary:focus {
  color: fade(@primary-color, 85%);
}
.ant-btn:hover, .ant-btn:focus {
  color: fade(@primary-color, 85%);
}
.ant-btn:active, .ant-btn.active {
  color: darken(@primary-color, 5%);
}
.ant-btn-primary {
  background-color: @primary-color;
  color: @secondary-color;
  font-weight: 600;
}
.ant-btn.ant-btn-primary:hover, .ant-btn.ant-btn-primary:focus {
  background-color: fade(@primary-color, 75%);
  color: @secondary-color;
}
.ant-btn.ant-btn-primary:active, .ant-btn.ant-btn-primary.active {
  background-color: darken(@primary-color, 5%);
  color: @secondary-color;
}
//  Pagination


.ant-list-pagination{
  margin-top: 25px;
}
.ant-pagination-item-active a{
  color: @color-white !important;
}

.ant-pagination-item{
  background-color:@color-white !important;
  border: 1px solid #E8E9EC !important;
  color:#4D4F5C!important;
  font-size: 13px !important;
  font-family: @font-family;
}

.ant-pagination-item-active{
  background-color:@primary-color !important;
  border: transparent !important;
  font-size: 13px !important;
}

.ant-pagination-disabled{

  background-color: #E9E9F0 !important;
}
.ant-pagination-disabled a{
  background-color: #E9E9F0 !important;
}

.ant-pagination-prev,
.ant-pagination-next{

  .anticon{
    color: #808495 !important;
    font-size: 15px!important;
    margin-top: 5px!important;
  }
  .ant-pagination-item-link{
    border: 1px solid #E8E9EC !important;
  }
}

.ant-pagination-prev:focus
.ant-pagination-item-link,
.ant-pagination-next:focus
.ant-pagination-item-link,
.ant-pagination-prev:hover
.ant-pagination-item-link,
.ant-pagination-next:hover
.ant-pagination-item-link{
  border: 1px solid #E8E9EC !important;
}

.circle-icon{
  width: 42px;
  height: 42px;
  box-shadow: 0px 11.6667px 35px rgba(139, 139, 139, 0.2);
  background-color: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 14px;
  svg{
    color:@text-color;
  }
}
.ant-btn-link{
  color: @text-color;
  font-family: @font-family;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  span{
    margin-left: 10px;
  }
  &:hover{
    color: fade(@text-color, 75%);
    text-decoration: none;
  }
}
.ant-btn-circle{
  width: 42px;
  height: 42px;
  box-shadow: 0px 11.6667px 35px rgba(139, 139, 139, 0.2)!important;
  background-color: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 14px!important;
  min-width: 42px !important;
  .anticon{
    font-size: 14px;
    color: @text-color;
    svg{
      color:@text-color;
    }
  }
}

.no-data{
  color:@text-color-secondary;
  font-weight: 700;
  font-size: 14px;
  font-family: @font-family;
  text-align: center;
}

.main-content{
  padding: 40px 20px;
  min-height: 800px;
  height: 100vh;
  margin:0 auto;
  width: 100%;
}
.main-header{
  width: 100%;
  margin:0 auto;
  padding: 30px 20px;
}


//  Responsive

@media (max-width: @screen-xs) {
  .ant-btn{
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: @screen-sm) {
  .ant-layout-sider-zero-width-trigger{
    // right: 15px;
  }
  .ant-layout-header{
    padding: 0 15px;
  }
  .main-content,
  .message-content{
    //margin: 30px 15px;
  }


  .ant-layout-sider.ant-layout-sider-collapsed{
    flex: 0 0 0!important;
    max-width: 0!important;
    min-width: 0!important;
    width: 0!important;
    .ant-layout-sider-zero-width-trigger{
      // right: -40px;
    }
  }

  .inbox-popover,
  .notification-popover{
    width: 95%;
    left: 10px !important;
    .ant-popover-arrow{
      display: none;
    }
  }

  //  Ant table

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td{
    padding: 4px 4px !important;
  }

  // notifications

  .notifications{
    display: block;
    button{
      margin: 10px 0 10px;
    }
    .content-left{
      width: 100%;
    }
  }

  .ant-layout.ant-layout-has-sider{
    overflow-x: hidden;
  }
}
@media (min-width: @screen-md) {

  // notifications

  .notifications{
    display: block;
    button{
      margin: 10px 10px 10px;

      &:first-child{
        margin-left: 0;
      }
    }
    .content-left{
      width: 100%;
    }
  }
  .main-content{
    max-width: 520px;
  }
  .main-header{
    max-width: 520px;
  }
}
@media (min-width: @screen-lg) {
  .main-content,
  .message-content{
    //margin: 30px 50px 30px 50px;
  }

  // notifications

  .notifications{
    display: flex;
    button{
      margin: 0 10px;
    }
    .content-left{
      width: 67%;
    }
  }

  .main-content{
    max-width: 700px;
  }
  .main-header{
    max-width: 700px;
  }
}
@media (min-width: @screen-xl) {
  .main-content{
    max-width: 1100px;
  }
  .main-header{
    max-width: 1100px;
  }
}
@media (min-width: 1201px) and (max-width: 1415px){
  .notifications{
    button{
      margin: 0 0 10px 0;
    }
    .content-left{
    }
  }
}
@media (min-width: 1356px) and (max-width: 1410px){
  .notifications{
    button{
      margin: 0 10px 10px 0;
    }
    .content-left{
    }
  }
}

